import { Reducer } from 'react';
import * as Action from './AdminPanel.actions';
import { withLoadable } from 'utils/hoc';
import { AdminPanelActionTypes, AdminPanelState } from './AdminPanel.interfaces';
import { IDetailedEvent, ITag } from 'interfaces';

const initialState: AdminPanelState = {
  loading: false,
  error: null,
  users: [],
  oneUserForEdit: null,
  events: [],
  tags: [],
  zones: [],
  comments: [],
  totalEvents: 0,
  totalUsers: 0,
  totalTags: 0,
  totalComments: 0,
  currentEventStatus: '',
  currentEventPage: 1,
  currentUserPage: 1,
  currentTagPage: 1,
  currentCommentsPage: 1,
  plans: [],
  packs: [],
  csvEvents: [],
  upcomingEvents: [],
  totalUpcomingEvents: 0,
  shareable: [],
  shared: [],
  pastEvents: [],
  totalPastEvents: 0,
  eventsPageState: { page: 1, tab: 'upcoming' },
  usersPageFilters: {},
  latestCreatedTag: null,
  entitiesPageFilters: {},
  entities: [],
  totalEntities: 0,
  currentEntityPage: 1,
  entityToEdit: null,
  isAliasAvailable: null,
  verifyAliasError: null,
};

const reducer: Reducer<AdminPanelState, AdminPanelActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.results,
        totalUsers: action.payload.count,
      };
    }

    case Action.GET_USERS: {
      return {
        ...state,
        currentUserPage: action.payload.query?.page || 1,
      };
    }

    case Action.GET_USER_SUCCESS: {
      return {
        ...state,
        oneUserForEdit: action.payload,
      };
    }

    case Action.PATCH_UPDATE_USER_SUCCESS: {
      return {
        ...state,
        oneUserForEdit: null,
      };
    }

    case Action.GET_ALL_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.payload.results,
        totalEvents: action.payload.count,
      };
    }

    case Action.GET_ALL_EVENTS: {
      return {
        ...state,
        currentEventPage: action.payload.query.page,
        currentEventStatus: action.payload.query.status,
      };
    }

    case Action.GET_ALL_TAGS: {
      return {
        ...state,
        currentTagPage: action.payload.query.page,
      };
    }

    case Action.GET_ALL_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload.results,
        totalTags: action.payload.count,
      };
    }

    case Action.GET_ZONES_SUCCESS: {
      return {
        ...state,
        zones: action.payload.results,
      };
    }
    case Action.CREATE_TAG_SUCCESS: {
      return {
        ...state,
        latestCreatedTag: action.payload,
      };
    }

    case Action.UPDATE_TAG_SUCCESS: {
      return {
        ...state,
        tags: state.tags.map((e: ITag) => (action.payload.id === e.id ? action.payload : e)),
      };
    }

    case Action.GET_ALL_PLANS_SUCCESS: {
      return {
        ...state,
        plans: action.payload.results,
      };
    }

    case Action.GET_PACKS_SUCCESS: {
      return {
        ...state,
        packs: action.payload.results,
      };
    }

    case Action.UPLOAD_CSV_SUCCESS: {
      return {
        ...state,
        csvEvents: [...state.csvEvents, ...action.payload],
      };
    }
    case Action.CLEAR_USER: {
      return {
        ...state,
        oneUserForEdit: null,
      };
    }
    case Action.CLEAR_LATEST_CREATED_TAG: {
      return {
        ...state,
        latestCreatedTag: null,
      };
    }
    case Action.GET_ALL_COMMENTS: {
      return {
        ...state,
        currentCommentsPage: action.payload.query.page,
      };
    }

    case Action.GET_ALL_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: action.payload.results,
        totalComments: action.payload.count,
      };
    }

    case Action.GET_PAST_EVENTS: {
      return {
        ...state,
        currentEventStatus: action.payload.query.status,
      };
    }
    case Action.GET_UPCOMING_EVENTS: {
      return {
        ...state,
        currentEventStatus: action.payload.query.status,
      };
    }

    case Action.GET_PAST_EVENTS_SUCCESS: {
      return {
        ...state,
        pastEvents: action.payload.results,
        totalPastEvents: action.payload.count,
      };
    }
    case Action.GET_UPCOMING_EVENTS_SUCCESS: {
      return {
        ...state,
        upcomingEvents: action.payload.results,
        totalUpcomingEvents: action.payload.count,
      };
    }
    case Action.GET_SHARED_EVENTS_SUCCESS: {
      return {
        ...state,
        shareable: action.payload.shareable,
        shared: action.payload.shared,
      };
    }

    case Action.CLEAR_CONTRIBUTOR_TRANSCRIPTION: {
      const { oneUserForEdit } = state;
      if (oneUserForEdit && oneUserForEdit.entity.audio_url) {
        const updatedUser = {
          ...oneUserForEdit,
          audio_url: '',
          transcription: '',
        };
        return {
          ...state,
          oneUserForEdit: updatedUser,
        };
      }
      return state;
    }

    case Action.SET_EVENTS_PAGE_STATE: {
      return {
        ...state,
        eventsPageState: action.payload,
      };
    }

    case Action.SET_USERS_PAGE_FILTERS: {
      return {
        ...state,
        usersPageFilters: action.payload,
      };
    }

    case Action.SET_ENTITIES_PAGE_FILTERS: {
      return {
        ...state,
        entitiesPageFilters: action.payload,
      };
    }

    case Action.EVENT_STATUS_UPDATE_SUCCESS: {
      const updateEvents = (events: IDetailedEvent[]) =>
        events?.map((event) => (event.id === action.payload.id ? action.payload : event));
      return {
        ...state,
        upcomingEvents: updateEvents(state.upcomingEvents),
        pastEvents: updateEvents(state.pastEvents),
      };
    }

    case Action.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        upcomingEvents: state.upcomingEvents.filter((event) => event.id != action.payload.id),
        pastEvents: state.pastEvents.filter((event) => event.id != action.payload.id),
      };
    }

    case Action.GET_ENTITIES_SUCCESS: {
      return {
        ...state,
        entities: action.payload.results,
        totalEntities: action.payload.count,
      };
    }

    case Action.DEACTIVATE_ENTITY_SUCCESS: {
      return {
        ...state,
        entities: state.entities.filter((entity) => entity.id !== action.payload),
      };
    }

    case Action.ACTIVATE_ENTITY_SUCCESS: {
      return {
        ...state,
        entities: state.entities.map((entity) =>
          entity.id === action.payload ? { ...entity, is_active: true } : entity,
        ),
      };
    }

    case Action.CREATE_ENTITY_SUCCESS: {
      return {
        ...state,
        entities: [...state.entities, action.payload],
      };
    }

    case Action.SET_ENTITY_TO_EDIT:
    case Action.GET_ENTITY_TO_EDIT_SUCCESS: {
      return {
        ...state,
        entityToEdit: action.payload,
      };
    }
    case Action.UPDATE_ENTITY_SUCCESS: {
      return {
        ...state,
        entities: state.entities.map((entity) => (entity.id === action.payload.id ? action.payload : entity)),
        entityToEdit: state.entityToEdit?.id === action.payload.id ? action.payload : state.entityToEdit,
      };
    }
    case Action.ADD_ENTITY_CREDITS_SUCCESS: {
      return {
        ...state,
        entities: state.entities.map((entity) =>
          entity.id === action.payload.id
            ? { ...entity, count_of_credits: entity.count_of_credits + action.payload.credits }
            : entity,
        ),
      };
    }
    case Action.VERIFY_ALIAS_SUCCESS: {
      return {
        ...state,
        isAliasAvailable: true,
        verifyAliasError: null,
      };
    }
    case Action.VERIFY_ALIAS_FAILURE: {
      return {
        ...state,
        isAliasAvailable: false,
        verifyAliasError: action.error,
      };
    }

    case Action.TOGGLE_PHONE_AVAILABLE_SUCCESS: {
      const updateEventDetails = (events: IDetailedEvent[]) => {
        return events.map((event) => {
          if (event.details.some((ed) => ed.id === action.payload.id)) {
            return {
              ...event,
              details: event.details.map((ed) =>
                ed.id === action.payload.id
                  ? {
                      ...ed,
                      is_phone_available: action.payload.is_phone_available,
                    }
                  : ed,
              ),
            };
          }
          return event;
        });
      };

      return {
        ...state,
        pastEvents: updateEventDetails(state.pastEvents),
        upcomingEvents: updateEventDetails(state.upcomingEvents),
      };
    }

    default:
      return state;
  }
};

export const usersReducer = withLoadable([
  Action.GET_USERS,
  Action.DEACTIVATE_USER,
  Action.POST_CREATE_USER,
  Action.GET_USER,
  Action.PATCH_UPDATE_USER,
  Action.GET_ALL_EVENTS,
  Action.GET_ALL_TAGS,
  Action.DELETE_TAG,
  Action.CREATE_TAG,
  Action.GET_ZONES,
  Action.UPDATE_TAG,
  Action.GET_ALL_PLANS,
  Action.CREATE_PLAN,
  Action.PATCH_PLAN,
  Action.DELETE_PLAN,
  Action.GET_PACKS,
  Action.CREATE_PACK,
  Action.PATCH_PACK,
  Action.DELETE_PACK,
  Action.UPLOAD_CSV,
  Action.GET_ALL_COMMENTS,
  Action.ADMIN_DELETE_COMMENT,
  Action.GET_PAST_EVENTS,
  Action.GET_UPCOMING_EVENTS,
  Action.GET_SHARED_EVENTS,
  Action.GET_ENTITIES,
])(reducer);
