import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Row } from 'antd';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
  position: relative;

  .authentication-logo img {
    width: 302px;
    height: 85px;
    margin-top: 85px;
  }
`;

export const FormWrapper = styled.div`
  max-width: 558px;
  width: 100%;
  background: #ffffff;
  margin-top: 45px;
  margin-bottom: 130px;
  padding: 65px 50px 18px;
  border-radius: 10px;

  .signin-btn {
    height: 51px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  > a {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-decoration: underline;

    &:hover,
    &:focus {
      outline: none;
      color: ${themeGet('primary.0', '#EE3932')};
    }
  }
`;

export const Title = styled.h2`
  color: ${themeGet('primary.0', '#EE3932')};
  font-size: 27px;
  font-weight: 800;
  margin-bottom: 26px;
  text-align: center;
`;
export const SocialLoginWrapper = styled(Row)`
  margin-bottom: 40px;
`;
export const TitleInfo = styled.p`
  color: #000;
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 45px;
  margin-top: 10px;
  @media only screen and (max-width: 1600px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 1440px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-weight: 600;
  color: #000;
  line-height: 1;

  &.form-footer {
    margin-top: 24px;
  }

  a {
    color: #000;
    margin-left: 5px;
    text-decoration: underline;

    &:hover,
    &:focus {
      outline: none;
      color: ${themeGet('primary.0', '#EE3932')};
    }
  }
`;

export const BannerWrapper = styled.div`
  width: 57%;
  @media only screen and (max-width: 1200px) {
    width: 50%;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }

  > div {
    position: fixed;
    width: 57%;
    top: 0;
    right: 0;

    @media only screen and (max-width: 1200px) {
      width: 50%;
    }
  }
`;

export const PoweredBy = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  gap: 2px;
  margin-top: 30px;

  &.footer {
    color: #807e7e;
    margin-top: 0;
    font-size: 14px;

    .anto {
      font-weight: bold;

      a {
        color: #807e7e;
      }
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

interface CTAWrapperProps {
  customEmailValidationColor?: string | null;
}

export const EmailActivationCTAWrapper = styled.div<CTAWrapperProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 31px;
  justify-content: center;

  a {
    width: 200px;
    height: 129px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;

    span {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &.cta-external {
      background-color: #eeedf2;

      img {
        width: 113px;
        height: 74px;
        margin: auto;
      }

      span {
        color: #000;
      }
    }

    &.cta-internal {
      background-color: ${themeGet('primary.0', '#EE3932')};

      .profile-icon {
        width: 67px;
        height: 67px;
        background-color: #eeedf2;
        border-radius: 50%;
        padding: 10px;
        margin: auto;

        div {
          height: 47px;
          width: 47px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 30px;
            height: 34px;

            path {
              ${(props) =>
                props.customEmailValidationColor &&
                css`
                  fill: ${props.customEmailValidationColor};
                `}
            }
          }
        }
      }

      span {
        color: #fff;
      }
    }
  }
`;

export default Wrapper;
